import React from "react";
import _isEmpty from "lodash.isempty";


import Literal from "../Literal/LiteralContainer";

import styles from "./LogoComponent.scss";

const Logo = (props) => {
  const className = [];
  var logoUrl = "";
  if (
    props.isMyUser ||
    props.isAnonymousUser ||
    (!props.isAuthenticated && props.isPublicPage)
  ){
    logoUrl = props.sitefinityUrl;
    className.push(styles["ut-logo__musafir-svg-logo"]);
  }   
  else {
    logoUrl = props.appUrl;
    className.push(styles["ut-logo__musafir-logo"]);
  }

  if (props.isNotTransparentBackground) {
    className.push(styles["ut-logo__color-white"]);
  }

  if (props.showLogo) {
    className.push(styles["ut-logo__show-on-mbl"]);
  }

  if (props.hideOnTab) {
    className.push(styles["ut-logo__hide-on-tab"]);
  }

  return (
    <>
      <a
        href={ logoUrl || ""}
        className={className.join(" ")}
      >
        <Literal route="common" lKey="BusinessName" />
      </a>
    </>
  );
};

export default Logo;
