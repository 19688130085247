import { connect } from "react-redux";

import Logo from "./LogoComponent";

const mapStateToProps = (state) => {
  return {
    application: state.app.application,
    isMyUser: state.app.isMyUser,
    user: state.app.loggedInUser,
    isAuthenticated: state.app.isAuthenticated || state.app.isOneTimeUser,
    isAnonymousUser: state.app.isAnonymousUser,
    isPublicPage:  state.app.isPublicPage,
    appUrl: state.app.appUrl,
    sitefinityUrl : state.app.sitefinityUrl
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Logo);
